import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchCandidates } from '../../../../Services/CandidatesAPI';
import { fetchDataAgnostic, fetchDataAuth0 } from '../../../../Services/dataApi';
import { clone } from '../../../../Services/schedule';
import { RouterPrompt } from '../../Common/RouterPrompt/RouterPrompt';
import AdminManageWaitlist from '../AdminManageWaitlist/AdminManageWaitlist';
import AdminCommunicationHistoryLanding from './AdminCommunicationHistory/AdminCommunicationHistoryLanding';
import AdminCommunicationSettings from './AdminCommunicationSettings/AdminCommunicationSettings';
import AdminCommunicationsNavigation from './AdminCommunicationsNavigation/AdminCommunicationsNavigation';
import AdminSendMessage from './AdminSendMessage/AdminSendMessage';
import { UncontrolledTooltip } from 'reactstrap';

const AdminCommunicationsLanding = ({ activeDepartment, currentInterviewDates }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [tabToGo, setTabToGo] = useState(1);
  const [token, setToken] = useState();
  const [candidates, setCandidates] = useState();
  const [interviewDates, setInterviewDates] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [departmentSettings, setDepartmentSettings] = useState();
  const [pageChanges, setPageChanges] = useState({
    sendMessage: false,
    history: false,
    settings: false,
    manageWaitlist: false,
  }); // Object with boolean for if page has changes
  const { path } = useRouteMatch();
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  const { getTokenSilently } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    getCandidates();
    getDepartment();
  }, []);

  useEffect(() => {
    if (currentInterviewDates) {
      formatThenSetInterviewDates(currentInterviewDates);
    }
  }, [JSON.stringify(currentInterviewDates)]);

  useEffect(() => {
    if (pathname && !hasChanges) {
      if (pathname.indexOf('/sendmessages') >= 0) {
        if (activeTab != 1) {
          setActiveTab(1);
          setTabToGo(1);
        }
      } else if (pathname.indexOf('/waitlist') >= 0) {
        if (activeTab != 2) {
          setActiveTab(2);
          setTabToGo(2);
        }
      } else if (pathname.indexOf('/history') >= 0) {
        if (activeTab != 3) {
          setActiveTab(3);
          setTabToGo(3);
        }
      } else {
        setActiveTab(1);
        setTabToGo(1);
      }
    }
  }, [pathname]);

  useEffect(() => {
    let newHasChanges = false;
    Object.values(pageChanges).forEach((value) => {
      if (value) {
        newHasChanges = true;
      }
    });
    setHasChanges(newHasChanges);
  }, [pageChanges]);

  const getDepartment = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/settings',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setDepartmentSettings(res.data[0]);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const getCandidates = () => {
    getTokenSilently()
      .then((token) => {
        setToken(token);
        // getActiveInterviewDates();
        fetchCandidates(token, {
          pk_Department: dContext.department.pk_Department,
          pk_Season: dContext.season.pk_Season,
          callback: (res) => {
            for (const key in res) {
              const { WaitlistDates } = res[key];
              let waitListString = '';
              if (WaitlistDates)
                WaitlistDates.forEach((item) => {
                  const { PreferenceOrder, DateOfInterview } = item;
                  if (PreferenceOrder !== null) {
                    if (waitListString === '') waitListString += '<br/>';
                    waitListString += `Choice #${PreferenceOrder + 1}:  ${moment(DateOfInterview).format(
                      'ddd, MMM DD, YYYY',
                    )}<br/>`;
                  }
                });
              res[key].waitListString = waitListString !== '' ? waitListString : null;
            }
            setCandidates(Object.values(res));
          },
        });
      })
      .catch((err) => {});
  };

  const formatThenSetInterviewDates = (array) => {
    const arrayWithMoment = array.map((item) => ({ ...item, MomentDateOfInterview: moment(item.DateOfInterview) }));
    const sortedArray = arrayWithMoment.sort((a, b) => a.MomentDateOfInterview - b.MomentDateOfInterview);

    setInterviewDates(sortedArray);
  };

  const updateChanges = (key, value) => {
    const newChanges = clone(pageChanges);
    newChanges[key] = value;
    setPageChanges(newChanges);
  };

  const renderContent = (activeTab) => {
    switch (activeTab) {
      case 1:
        return (
          <AdminSendMessage
            setPageChanges={setPageChanges}
            pageChanges={pageChanges}
            interviewDates={interviewDates}
            candidates={candidates}
            activeDepartment={activeDepartment}
            getCandidates={getCandidates}
          />
        );
      case 2:
        return (
          <AdminManageWaitlist
            interviewDates={interviewDates}
            candidates={candidates}
            activeDepartment={activeDepartment}
            setActiveTab={setActiveTab}
            token={token}
          />
        );
      case 3:
        return <AdminCommunicationHistoryLanding activeDepartment={activeDepartment} token={token} />;

      default:
        return (
          <AdminSendMessage
            candidates={candidates}
            activeDepartment={activeDepartment}
            setCandidates={setCandidates}
            getCandidates={getCandidates}
          />
        );
    }
  };

  const { EnableAutoSlotState } = departmentSettings || {};

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: `70%` }}>
          <AdminCommunicationsNavigation
            activeTab={activeTab}
            setActiveTab={(newActiveTab) => {
              setTabToGo(newActiveTab);
              if (!hasChanges) {
                setActiveTab(newActiveTab);
              }
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center' }}>
          <div
            id="autoRegistration_info"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontStyle: 'italic' }}
          >
            <span>
              Auto Registration is:{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  color: EnableAutoSlotState === undefined ? null : EnableAutoSlotState ? 'green' : 'red',
                }}
              >
                {EnableAutoSlotState === undefined ? '----' : EnableAutoSlotState ? 'Enabled' : 'Disabled'}
              </span>{' '}
            </span>
            {EnableAutoSlotState != null ? (
              <UncontrolledTooltip target="autoRegistration_info">
                {EnableAutoSlotState
                  ? 'Candidates will automatically register for interview sessions.'
                  : 'Candidates will not automatically register for interview sessions.'}
              </UncontrolledTooltip>
            ) : null}
          </div>
        </div>
      </div>
      {renderContent(activeTab)}

      <RouterPrompt
        hasChanges={hasChanges}
        okLabel={'Discard Changes'}
        onOk={() => {
          setActiveTab(tabToGo);
          setPageChanges({
            sendMessage: false,
            history: false,
            settings: false,
            manageWaitlist: false,
          });

          return true;
        }}
      />
    </div>
  );
};

export default AdminCommunicationsLanding;
