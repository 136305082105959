import { faCog, faUserAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import DateDisplay from '../DateDisplay/DateDisplay';
import styled from 'styled-components';
import {
  getMeetingIdFromUrl,
  removeCorrectEvalTOken,
  zoomPopupJSX,
  urlWithHttpProtocol,
} from '../../../../Common.functions';
import Style from './UserInformation.style';
import Swal from 'sweetalert2';
import { truncate } from 'Services/candidates';
import NotificationTest from 'Body/RezRATE/Common/NotificationTest/NotificationTest';

const CustomDropdown = styled(Dropdown)`
  button {
    background-color: transparent !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: left !important;
  }
`;

const UserInformation = (props) => {
  const [password, setPassword] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showZoomLink, setShowZoomLink] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [logOut, setLogout] = useState(false);

  const { userInfo, isMobile, logoutClick, dContext, date, seasonType, dateArray, setDate } = props;
  const {
    UserFirst,
    UserLast,

    UserPhotoUrl,
  } = userInfo || {};

  useEffect(() => {
    // console.log('props: ', props);
  }, [props]);

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const getPasswordFromUrl = (zoomUrl) => {
    const passwordSplit = zoomUrl.split('?');
    if (passwordSplit[1]) {
      const withoutKey = passwordSplit[1].split('=');
      if (withoutKey[1]) {
        setPassword(withoutKey[1]);
      }
    }
  };

  const renderAvatar = () => {
    if (UserPhotoUrl && UserPhotoUrl.trim() !== '') {
      return <img src={UserPhotoUrl} alt="avatar" />;
    }
    return <FontAwesomeIcon icon={faUserAlt} style={{ fontSize: 40, color: 'white' }} />;
  };

  const user = userInfo;

  if (!user)
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          id="logout_button"
          color="danger"
          onClick={(e) => {
            e.preventDefault();
            logoutClick();
          }}
        >
          <div>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </div>
        </Button>
      </div>
    );

  if (logOut) {
    return <Redirect to="/" />;
  }

  const renderDesktop = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: isMobile ? 40 : 90,
              // alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderAvatar()}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: `calc(100% - ${isMobile ? '40px' : '90px'})`,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // paddingLeft: 10,
                textAlign: 'left',
                fontSize: 15,
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'left',
                width: 280,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  paddingRight: 30,
                }}
              >
                {truncate(`${UserFirst} ${UserLast}`, 35)}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 245, display: 'flex', flexDirection: 'column' }}>
                <DateDisplay
                  date={date}
                  seasonType={seasonType}
                  season={dContext.season}
                  dateArray={dateArray}
                  setDate={setDate}
                />
              </div>

              <div style={{ width: 40, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <Button
                  id="logout_button"
                  //
                  color="danger"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    logoutClick();
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
              <div style={{ width: 245, display: 'flex', flexDirection: 'column' }}>
                <Button
                  size="sm"
                  style={{ width: '100%' }}
                  color={date && date.allDayRoom ? 'warning' : 'secondary'}
                  onClick={() => {
                    if (date.allDayRoom) {
                      window.open(urlWithHttpProtocol(date.allDayRoom), '_blank').focus();
                    } else {
                      Swal.fire({
                        title: 'No Break Room',
                        text: 'There is no break room for this date.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                      });
                    }
                  }}
                >
                  {date && date.allDayRoom ? 'Visit Break Room' : 'No break room configured'}
                </Button>
              </div>

              <div style={{ width: 30, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <NotificationTest size="sm" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: `100%`,
              marginLeft: 0,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
              <div style={{ width: 'calc(100% - 60px)', display: 'flex', flexDirection: 'column' }}>
                <DateDisplay
                  date={date}
                  seasonType={seasonType}
                  season={dContext.season}
                  dateArray={dateArray}
                  setDate={setDate}
                />
              </div>

              <div style={{ width: 50, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <Button
                  id="logout_button"
                  //
                  size="sm"
                  color="danger"
                  style={{ width: 30 }}
                  onClick={(e) => {
                    e.preventDefault();
                    logoutClick();
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                </Button>
                {/* <UncontrolledPopover
                  target="userProfilePicture"
                  placement="top"
                  flip={false}
                  modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                >
                  <PopoverBody>{truncate(`${UserFirst} ${UserLast}`, 25)}</PopoverBody>
                </UncontrolledPopover> */}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
              <Button
                size="sm"
                style={{ width: 'calc(100% - 60px)' }}
                color={date && date.allDayRoom ? 'warning' : 'secondary'}
                onClick={() => {
                  if (date.allDayRoom) {
                    window.open(urlWithHttpProtocol(date.allDayRoom), '_blank').focus();
                  } else {
                    Swal.fire({
                      title: 'No Break Room',
                      text: 'There is no break room for this date.',
                      icon: 'warning',
                      confirmButtonText: 'OK',
                    });
                  }
                }}
              >
                {date && date.allDayRoom ? 'Visit Break Room' : 'No break room configured'}
              </Button>
              <div style={{ width: 50, paddingLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <NotificationTest size="sm" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (isMobile) {
    return renderMobile();
  } else {
    return renderDesktop();
  }
};

export default UserInformation;
