import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { NotificationManager } from 'react-notifications';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, userExistsInSystem } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic, postDataAgnostic, putData } from '../../../../Services/dataApi';
import { clone } from '../../../../Services/schedule';
import AdminBulkImportUsers from '../AdminBulkImportUsers/AdminBulkImportUsers';
import AddUser from './AddUser/AddUser';
import style from './AdminTeam.style';
import './AdminTeam.style.css';
import UserDetails from './UserDetails/UserDetails';
import UserList from './UserList/UserList';

const AdminTeam = () => {
  const [emailThatExists, setEmailThatExists] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [user, setUser] = useState({});
  const [showAddUser, setShowAddUser] = useState(false);
  const [genericUserCreationError, setGenericUserCreationError] = useState(false);
  const [hasUserDetailChanges, setHasUserDetailChanges] = useState(false);
  const [scaleData, setScaleData] = useState(null);

  const [token, setToken] = useState();
  const [users, setUsers] = useState([]);
  const [selfUserDetails, setSelfUserDetails] = useState();

  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently } = useAuth0();
  const authDetails = useAuth0();
  const alert = useAlert();

  useEffect(() => {
    getScale();
    getUsers();
    setSelfUserDetails(authDetails ? authDetails.user : {});
  }, []);

  const saveNewUser = (userObject) => {
    userObject.Auth0Email = userObject.UserEmail;
    const users = [userObject];
    let isValid = true;

    const requiredFields = ['UserFirst', 'UserLast', 'UserEmail'];
    requiredFields.forEach((f) => {
      if (!userObject[f] || userObject[f] == null) {
        isValid = false;
      }
    });
    const userExists = checkIfUserExists(userObject);

    if (userExists) {
      reactivateUser(userExists);
      if (
        userExists.EnableAdmin != userObject.EnableAdmin ||
        userExists.EnableEvaluator != userObject.EnableEvaluator
      ) {
        updateUser(userObject, userExists.pk_User);
      }
      setShowAddUser(false);
      return;
    }

    if (!isValid) {
      return;
    }
    setIsPosting(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/users',
          { pk_Department: dContext.department.pk_Department },
          { users },
          formatBearerToken(token),
        )
          .then(async (result) => {
            if (result.data.userExists) {
              setEmailThatExists(users[0].UserEmail);
              NotificationManager.error('A user with that email already exists in this department.');
            } else if (result.data.error) {
              setGenericUserCreationError(true);
              NotificationManager.error('Something went wrong...');
            } else {
              if (result.data.userExistInSystem) {
                userExistsInSystem(result.data.userObject);
              } else {
                NotificationManager.success('Success!', 'User Saved');
              }

              const newlyAddedUser = result.data ? result.data.newCandidates[0] : null;

              getUsers(newlyAddedUser);

              if (newlyAddedUser) {
                // console.log('post-add newlyAddedUser: ', newlyAddedUser);
                // returned canddiate has no pk, so nulled in getUsers and defaults to
                // getUser's result.data[0]
                setSelectedUser(newlyAddedUser);
              }
              setShowAddUser(false);
              setUser({});
              // TODO: Find a better way to get current state
              // setUsers((prevState) => {
              //   // const newSelectedUser = prevState.find((user) => {
              //   //   return result.data.pk_User === selectedUser.pk_User;
              //   // });

              //   console.log('setSelectedUser: ', selectedUser);
              //   setSelectedUser(newlyAddedUser);
              //   return prevState;
              // });
            }
          })
          .catch((err) => {
            setGenericUserCreationError(true);
            NotificationManager.error('Something went wrong...');
          })
          .finally(() => {
            setIsPosting(false);
          });
      })
      .catch((err) => {
        NotificationManager.error('Something went wrong...');
      });
  };

  const discardChanges = () => {
    setHasUserDetailChanges(false);
  };

  // this can be called when replacing a photo on a user to update the photo in the user list
  const updateUserPhotoInUserArray = (id, photoUrl) => {
    setUsers((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);

      clone.forEach((item) => {
        if (item.pk_User === id) {
          item.UserPhotoUrl = photoUrl;
        }
      });
      return clone;
    });
  };

  const reactivateUser = (user) => {
    setIsUpdatingUser(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/user/reactivate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_User: user.pk_User,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success(`${user.UserFirst || ''} ${user.UserLast || ''} (${user.Auth0Email}) has been reactivated`);
            setIsUpdatingUser(false);
            getUsers();
          })
          .catch((err) => {
            setIsUpdatingUser(false);
          });
      })
      .catch((err) => {
        setIsUpdatingUser(false);
      });
  };

  const updateUser = (user, pk_User_Actual) => {
    const { EnableAdmin, EnableEvaluator } = user;
    const userObject = {};
    if (EnableAdmin != null) {
      userObject.EnableAdmin = EnableAdmin;
    }

    if (EnableEvaluator != null) {
      userObject.EnableEvaluator = EnableEvaluator;
    }

    getTokenSilently()
      .then((token) => {
        putData(
          'department/user',
          { pk_Department: dContext.department.pk_Department, pk_User: pk_User_Actual },
          {
            userObject,
          },
          formatBearerToken(token),
        )
          .then((res) => {})
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const checkIfUserExists = (userObject) => {
    const userExists = users.find((u) => {
      return u.Auth0Email === userObject.Auth0Email && u.Deleted;
    });
    return userExists;
  };

  const getScale = (scale) => {
    getTokenSilently().then((token) => {
      fetchDataAgnostic(
        'department/questions',
        { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
        formatBearerToken(token),
      )
        .then((res) => {
          setScaleData(res.data);
        })
        .catch((err) => {
          console.log('getScale err: ', err);
        })
        .finally(() => {});
    });
  };
  const renderUserExistsError = () => {
    if (emailThatExists.trim() !== '') {
      if (user && user.UserEmail === emailThatExists) {
        return true;
      }
    }
    return false;
  };

  const handleChange = (key, value) => {
    const newUser = clone(user);
    newUser[key] = value;
    setUser(newUser);
  };

  const getUsers = (forcedUser) => {
    setIsUpdatingUser(true);
    getTokenSilently().then((token) => {
      fetchDataAgnostic(
        'department/users',
        {
          pk_Department: dContext.department.pk_Department,
        },
        formatBearerToken(token),
      )
        .then((res) => {
          setIsUpdatingUser(false);
          const rawUsers = Object.values(res.data);
          if (rawUsers && rawUsers.length == 1 && rawUsers[0].pk_User == null) {
            // no users and only has currentServerTime
            return;
          }

          setUsers(rawUsers);

          if (forcedUser) {
            const userToSelect = rawUsers.find((user) => {
              return user.pk_User == forcedUser.pk_User && !user.Deleted;
            });
            setSelectedUser(userToSelect);
          } else if (selectedUser) {
            const userToSelect = rawUsers.find((user) => {
              return user.pk_User == selectedUser.pk_User && !user.Deleted;
            });
            setSelectedUser(
              userToSelect,
              //  || rawUsers[0]
            );
          }
          // else {
          //   setSelectedUser(rawUsers[0]);
          // }
        })
        .catch((err) => {
          setIsUpdatingUser(false);
          const rawUsers = users.slice();
          if (selectedUser) {
            const userToSelect = rawUsers.find((user) => {
              return user.pk_User == selectedUser.pk_User;
            });
            setSelectedUser(userToSelect || rawUsers[0]);
          } else {
            setSelectedUser(rawUsers[0]);
          }
        })
        .finally(() => {
          return true;
        });
    });
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setBulkImportPopoverOpen(true);
  //   }, 100);
  // }, []);

  const renderAddUserModal = () => {
    return (
      <AddUser
        isOpen={showAddUser}
        user={user}
        setUser={setUser}
        setIsOpen={setShowAddUser}
        saveNewUser={saveNewUser}
        isPosting={isPosting}
        handleChange={handleChange}
        renderUserExistsError={renderUserExistsError}
      />
    );
  };

  return (
    <Row>
      <Col md={3}>
        <UserList
          discardChanges={discardChanges}
          hasUserDetailChanges={hasUserDetailChanges}
          users={users}
          getUsers={getUsers}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setShowAddUser={setShowAddUser}
        />
      </Col>
      <Col md={9}>
        <UserDetails
          hasUserDetailChanges={hasUserDetailChanges}
          setHasUserDetailChanges={setHasUserDetailChanges}
          selfUserDetails={selfUserDetails}
          isUpdatingUser={isUpdatingUser}
          updateUserPhotoInUserArray={updateUserPhotoInUserArray}
          selectedUser={selectedUser}
          dContext={dContext}
          token={token}
          scaleData={scaleData}
          onUpdate={() => {
            getUsers();
          }}
          onDeactivateUser={() => {
            if (selectedUser) {
              const { UserFirst, UserLast } = selectedUser;
              Swal.fire({
                text: `Are you sure you want to Deactivate ${UserFirst} ${UserLast}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#6c757d',
                confirmButtonText: 'Yes, Deactivate it!',
              }).then((result) => {
                if (result.value) {
                  // deleteEntry(pk_InterviewDate);
                }
              });
            }
          }}
        />
      </Col>
      {renderAddUserModal()}
    </Row>
  );
};

export default AdminTeam;
