import React, { useState, useEffect } from 'react';
import style from './ApplicantProfile.style';
import { Button, ButtonGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import ApplyPage from './ApplyPage/ApplyPage';
import { useHistory } from 'react-router';

import ApplicantAccount from './ApplyPage/ApplicantAccount/ApplicantAccount';
import { fetchDataAgnostic } from 'Services/dataApi';
import ApplicationsPage from './ApplicationsPage/ApplicationsPage';
import { formatBearerToken } from 'Common.functions';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

const ApplicantProfile = () => {
  const [showMode, setShowMode] = useState('applications');
  const [applicantDetails, setApplicantDetails] = useState();

  const history = useHistory();

  const { pathname, search } = history.location;
  const { isAuthenticated, login, getToken, isLoading } = useKindeAuth();

  useEffect(() => {
    if (getToken && isAuthenticated) {
      fetchApplicant();
    }
  }, [getToken, isAuthenticated]);

  useEffect(() => {
    if (pathname.indexOf('profile/applications') >= 0) {
      setShowMode('applications');
    } else if (pathname.indexOf('profile/new_application') >= 0) {
      setShowMode('new application');
    } else if (pathname.indexOf('profile/account') >= 0) {
      setShowMode('account');
    } else {
      setShowMode('applications');
    }
  }, [pathname]);

  // const fetchApplications = () => {
  //   getTokenSilently()
  //     .then((token) => {
  //       fetchDataAgnostic(
  //         'applicant/application/list',
  //         // { pk_Department: dContext.department.pk_Department },
  //         formatBearerToken(token),
  //       )
  //         .then((res) => {
  //           setTagChoices(res.data);
  //           // setIsLoading(false);
  //         })
  //         .catch((err) => {});
  //     })
  //     .catch((err) => {});
  // };

  const fetchApplicant = () => {
    getToken().then((token) => {
      fetchDataAgnostic('applicant/', null, formatBearerToken(token))
        .then((res) => {
          console.log('fetchApplicantt res.data: ', res.data);
          setApplicantDetails(res.data);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    });
  };

  const renderApplications = () => {
    return <ApplicationsPage applicantDetails={applicantDetails} />;
  };

  const renderNewApplication = () => {
    return <ApplyPage applicantDetails={applicantDetails} />;
  };

  const renderApplicantAcount = () => {
    return <ApplicantAccount applicantDetails={applicantDetails} />;
  };

  const renderContent = () => {
    switch (showMode) {
      case 'applications':
        return renderApplications();
      case 'new application':
        return renderNewApplication();
      case 'account':
        return renderApplicantAcount();
      default:
        return renderApplications();
    }
  };

  return (
    <div style={{ ...style.simpleRow, width: '100%' }}>
      <div style={style.simpleColumn}>
        <div style={{ ...style.simpleRow, padding: 5 }}>
          <ButtonGroup>
            <Button
              size="sm"
              color={showMode === 'applications' ? 'primary' : 'secondary'}
              style={{ width: 130, fontWeight: showMode === 'applications' ? 'bold' : null }}
              onClick={() => {
                history.push('/applicant/profile/applications');
                // setShowMode('applications');
              }}
            >
              Applications
            </Button>
            <Button
              size="sm"
              color={showMode === 'new application' ? 'primary' : 'secondary'}
              style={{ width: 160, fontWeight: showMode === 'new application' ? 'bold' : null }}
              onClick={() => {
                history.push('/applicant/profile/new_application');
                // setShowMode('new application');
              }}
            >
              New Application
            </Button>
            <Button
              size="sm"
              color={showMode === 'account' ? 'primary' : 'secondary'}
              style={{ width: 100, fontWeight: showMode === 'account' ? 'bold' : null }}
              onClick={() => {
                history.push('/applicant/profile/account');
                // setShowMode('account');
              }}
            >
              Account
            </Button>
          </ButtonGroup>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default ApplicantProfile;
