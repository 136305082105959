import React, { useEffect, useState } from 'react';
import style from './ApplicationField.style';
import { Button, CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const ApplicationField = (props) => {
  const { label, type, value, onChange, placeholder, required, fieldId, doValidate, disabled } = props;

  const [myValue, setMyValue] = useState();

  useEffect(() => {
    setMyValue(value);
  }, [label]);

  const renderInput = () => {
    switch (type) {
      case 'text':
        return (
          <InputGroup style={{ height: 'auto' }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ width: 200, textWrap: 'wrap', textAlign: 'left' }}>
                <span>
                  {label}
                  {required ? <b style={{ color: 'red' }}>*</b> : ''}
                </span>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              disabled={disabled}
              invalid={doValidate && !value}
              id={fieldId}
              value={myValue}
              type="text"
              style={{ height: 'auto', textWrap: 'wrap' }}
              onChange={(e) => {
                setMyValue(e.target.value);
                onChange(e);
              }}
            />
          </InputGroup>
        );
      case 'switch':
        return (
          <InputGroup style={{ height: 'auto' }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ width: 200, textWrap: 'wrap', textAlign: 'left' }}>
                {label}
                {required ? <b style={{ color: 'red' }}>*</b> : ''}
              </InputGroupText>
            </InputGroupAddon>
            <Input style={{ width: '0px', display: 'none' }} />
            <InputGroupAddon addonType="append" style={{ width: 'calc(100% - 200px)', height: 'auto' }}>
              <InputGroupText style={{ width: '100%', backgroundColor: 'white' }}>
                <CustomInput
                  disabled={disabled}
                  invalid={doValidate && !value}
                  type="switch"
                  label={myValue ? 'Yes' : 'No'}
                  id={fieldId}
                  name={fieldId}
                  checked={myValue}
                  // checked={showPastInterviewDates == 'false' ? false : true}
                  onChange={(e) => {
                    setMyValue(e.target.checked);
                    onChange(e);
                  }}
                />
              </InputGroupText>

              {/* <div style={{ ...style.simpleColumn, border: '1px solid gray', width: '100%', borderRadius: 5 }}>
                
              </div> */}
            </InputGroupAddon>
          </InputGroup>
        );
      default:
        return (
          <InputGroup style={{ height: 'auto' }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ width: 200, textWrap: 'wrap', textAlign: 'left' }}>{label}</InputGroupText>
            </InputGroupAddon>
            <Input
              disabled={disabled}
              id={fieldId}
              value={myValue}
              type="text"
              style={{ height: 'auto', textWrap: 'wrap' }}
              onChange={(e) => {
                setMyValue(e.target.value);
                onChange(e);
              }}
            />
          </InputGroup>
        );
    }
  };
  return (
    <div
      key={fieldId}
      style={{ ...style.simpleRow, marginBottom: 5, width: '100%', paddingLeft: 10, paddingRight: 10 }}
    >
      {renderInput()}
    </div>
  );
};

export default ApplicationField;
