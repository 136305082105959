import {
  faCheck,
  faPencilAlt,
  faSpinner,
  faPlus,
  faMinus,
  faUser,
  faCoffee,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Collapse, Input, Row, Table } from 'reactstrap';
import styled from 'styled-components';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { connect } from 'react-redux';
import {
  determineIcon,
  formatBearerToken,
  informUserEvalLocked,
  shouldJoinMeetingButtonBeVisisble,
} from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import LoadingDot from '../../../../LoadingDot/LoadingDot';
import { fetchDataAgnostic } from '../../../../Services/dataApi';
import style from './Evaluate.style';
import { clone } from 'lodash';
import Loading from 'Body/Statuses/Loading';

const faCheckColor = '#66B131';
const faPencilColor = '#FD9A00';
const faTimesColor = '#FF0000';

const EVAL_STATUS_STRING = 'Evaluation Status';

const evalLockedButton = {
  cursor: 'default',
  backgroundColor: 'grey',
};

const StatusWrapper = {
  display: 'flex',
  maxWidth: '100%',
  alignItems: 'center',
};

const Circle = styled.div`
  position: absolute;
  bottom: 5%;
  left: 110px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgb(51, 51, 51);
  background-color: #cc0000;
  width: 40px;
  height: 40px;
`;

const BlueButton = styled(Button)`
  background-color: rgb(17, 103, 165);
  color: #ffffff;
  border-top-color: #1167a5;
  border-right-color: #1167a5;
  border-bottom-color: #1167a5;
  border-left-color: #1167a5;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-right-radius: 3px 3px;
  border-bottom-right-radius: 3px 3px;
  border-bottom-left-radius: 3px 3px;
  border-top-left-radius: 3px 3px;
`;

const DownloadButton = styled.div`
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbb;
  border-radius: 50%;
  display: inline-block;
`;

const getDocuments = (selectedUser) => {
  if (selectedUser) {
    const { Documents } = selectedUser;
    if (Documents) {
      return Documents;
    }
  }
  return [];
};

const mapStateToProps = (state) => {
  const { userInfo } = state;
  return {
    userInfo,
  };
};

const Portfolio = (props) => {
  const {
    activeTab,
    allDayRoom,
    candidateEvaluationStatus,
    checkIfEvalsAreLocked,
    dataTypes,
    disableJoinMeetingButton,
    DNRStatus,
    evaluationsLocked,
    getEvalAndChangeTab,
    handleMeetingClick,
    candidateInfo,
    handleZoomClick,
    interviewIsToday,
    isEvaluate,
    isLoading,
    requestZoomMeeting,
    Schedules4Eval__pkUUID_Schedule,
    Schedules4EvalJoinManualURL,
    selectedTimeSlot,
    selectedUser, // is actually selectedCandidate. :V
    setLargePhoto,
    setSelectedUser,
    setShowLargePhoto,
    userInfo, // logged in users data, NOT the selectedUser
  } = props;
  const [disableButton, setDisableButton] = useState(false);

  const [showCandidateData, setShowCandidateData] = useState(true);
  const [showDocuments, setShowDocuments] = useState(true);
  const [currentTime, setCurrentTime] = useState(moment(new Date()));
  const [documentTypes, setDocumentTypes] = useState([]);
  const [flexEventCandidates, setFlexEventCandidates] = useState([]);
  const [selectedFlexEventCandidate, setSelectedFlexEventCandidate] = useState('');

  const [showLanguages, setShowLanguages] = useState(true);
  const [showHobbies, setShowHobbies] = useState(true);

  const [documents, setDocuments] = useState(getDocuments(selectedUser));
  const url = '';
  const priorityUrl = Schedules4EvalJoinManualURL;
  const history = useHistory();
  const dContext = useContext(DepartmentContext);

  const { pk_TimeSlot, isFlexEvent, isFiller, isCustomMeeting, isScheduleBlock, isPassingPeriod } =
    selectedTimeSlot || {};
  const { getTokenSilently } = useAuth0();

  const { user = {} } = userInfo || {};
  const { DisableCandidateEvaluations, HideCandidateDocuments, HideCandidateScores } = user || {};

  const tick = () => {
    setCurrentTime(moment(new Date()));
  };

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    getDocumentTypes();
  }, []);

  useEffect(() => {
    if (selectedTimeSlot) {
      if (selectedTimeSlot.isFlexEvent) {
        const { FlexEvent = {} } = selectedTimeSlot || {};
        const { Candidates = [] } = FlexEvent;
        setFlexEventCandidates(Candidates);
        if (Candidates.length === 1) {
          const matchingCandidate = candidateInfo.find((c) => c.pk_Candidate === Candidates[0].pk_Candidate);
          setSelectedFlexEventCandidate(matchingCandidate);
        } else {
          setSelectedFlexEventCandidate('');
        }
      } else {
        setFlexEventCandidates([]);
        setSelectedFlexEventCandidate('');
        setDocuments(getDocuments(selectedUser));
        setShowCandidateData(true);
        setShowDocuments(true);
        setShowLanguages(true);
        setShowHobbies(true);
      }
    }
  }, [selectedTimeSlot]);

  useEffect(() => {
    if (selectedFlexEventCandidate) {
      setDocuments(getDocuments(selectedFlexEventCandidate));
      setSelectedUser(selectedFlexEventCandidate);
    } else {
      setDocuments([]);
    }
  }, [selectedFlexEventCandidate]);

  const getDocumentTypes = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'documentTypes',
          {
            pk_Department: dContext.department.pk_Department,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setDocumentTypes(res.data);
          })
          .catch((err) => {
            console.log('getDocumentTypes err: ', err);
          });
      })
      .catch((err) => {
        console.log('getDocumentTypes getTokenSilently err: ', err);
      });
  };

  /**
   * Renders the scores and awards for the selected candidate.
   * If isLoading is true, displays a loading message.
   * If isFlexEvent is true, uses selectedFlexEventCandidate as the candidate, otherwise uses selectedUser.
   * Filters the dataTypes based on DisplayOnRank and isEvaluate flags.
   * Renders a div for each item in dataTypes that meets the filter criteria.
   * Each div displays the Alias and corresponding value from the candidateToUse object.
   *
   * @returns {JSX.Element[] | undefined} An array of JSX elements representing the scores and awards, or undefined if candidateToUse or dataTypes is falsy.
   */
  const renderScoresAndAwards = () => {
    if (isLoading) {
      return <div style={{ ...style.detailRow, justifyContent: 'center', alignItems: 'center' }}>Loading. . .</div>;
    }
    const { isFlexEvent } = selectedTimeSlot || {};
    const candidateToUse = isFlexEvent ? selectedFlexEventCandidate : selectedUser || {};

    if (candidateToUse && dataTypes) {
      return dataTypes.map((item, i) => {
        if ((item.DisplayOnRank || isEvaluate) && candidateToUse[item.Name] != null) {
          return (
            <div
              key={item.ScoresAwardsCandTitle}
              style={{ ...style.detailRow, backgroundColor: i % 2 == 0 ? '#f2f2f2' : null }}
            >
              <div style={{ ...style.simpleColumn, width: '30%' }}>
                <div style={{ ...style.simpleRow, fontWeight: 'bold' }}>{item.Alias}</div>
              </div>
              <div style={{ ...style.simpleColumn, width: '70%', textAlign: 'left', paddingLeft: 20 }}>
                <div style={style.simpleRow}>{candidateToUse[item.Name]}</div>
              </div>
            </div>
          );
        }
      });
    }
  };

  const determineStatusButton = (candidate) => {
    const { EnableDoNotRank, Prescreen1OrEvaluation2, Status = '' } = candidateEvaluationStatus;

    const statusUpperCase = Status.toUpperCase();

    if (DisableCandidateEvaluations) {
      return;
    }

    const onEvalTab = Number(activeTab) === 3;
    if (statusUpperCase === 'COMPLETE') {
      return (
        <div>
          {!onEvalTab && (
            <Button
              className="longest-button-min-width"
              disabled={disableButton}
              color="warning"
              onClick={(e) => {
                e.preventDefault();
                if (checkIfEvalsAreLocked()) {
                  informUserEvalLocked();
                  return;
                }
                setDisableButton(true);
                getEvalAndChangeTab()
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    setDisableButton(false);
                  });
              }}
            >
              Modify Evaluation
            </Button>
          )}
        </div>
      );
    }

    if (statusUpperCase === 'IN PROGRESS') {
      return (
        <div>
          {!onEvalTab && (
            <Button
              className="longest-button-min-width"
              color="primary"
              style={evaluationsLocked ? evalLockedButton : {}}
              disabled={disableButton}
              onClick={(e) => {
                e.preventDefault();
                if (checkIfEvalsAreLocked()) {
                  informUserEvalLocked();
                  return;
                }
                setDisableButton(true);
                getEvalAndChangeTab()
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    setDisableButton(false);
                  });
              }}
            >
              Continue Evaluation
            </Button>
          )}
        </div>
      );
    }

    return (
      <div>
        {!onEvalTab && (
          <Button
            className="longest-button-min-width"
            color="primary"
            style={evaluationsLocked ? evalLockedButton : {}}
            disabled={disableButton}
            onClick={(e) => {
              e.preventDefault();
              if (checkIfEvalsAreLocked()) {
                informUserEvalLocked();
                return;
              }
              setDisableButton(true);
              getEvalAndChangeTab()
                .then((res) => {})
                .finally(() => {
                  setDisableButton(false);
                });
            }}
          >
            Begin Evaluation
          </Button>
        )}
      </div>
    );
  };

  const determineStatusIcon = (candidate) => {
    const { EnableDoNotRank, Prescreen1OrEvaluation2, Status = '' } = candidateEvaluationStatus;
    const statusUpperCase = Status.toUpperCase();
    const onEvalTab = Number(activeTab) === 3;
    if (statusUpperCase === 'COMPLETE' || statusUpperCase === 'UNABLE TO EVALUATE') {
      return (
        <div style={StatusWrapper}>
          <div style={{ color: faCheckColor }}>
            <p data-tip={EVAL_STATUS_STRING}>
              <FontAwesomeIcon color={faCheckColor} icon={faCheck} /> Complete
              {statusUpperCase === 'UNABLE TO EVALUATE' && (
                <p style={{ margin: 0, fontSize: '0.8em', color: '#000000' }}>(Unable To Evaluate)</p>
              )}
            </p>
          </div>
        </div>
      );
    }

    if (statusUpperCase === 'IN PROGRESS') {
      return (
        <Row style={StatusWrapper}>
          <Col md="12" style={{ color: faPencilColor }}>
            <p data-tip={EVAL_STATUS_STRING}>
              <FontAwesomeIcon color={faPencilColor} icon={faPencilAlt} /> In Progress
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row style={StatusWrapper}>
        <Col md="6"></Col>
        <Col md="6"></Col>
      </Row>
    );
  };

  const renderDocuments = (document) => {
    if (isLoading) {
      return <div style={{ ...style.detailRow, justifyContent: 'center', alignItems: 'center' }}>Loading. . .</div>;
    }

    if (!document || document.length === 0) {
      return (
        <tr>
          <td>No Assigned Documents</td>
        </tr>
      );
    }

    return documents.map((document) => {
      const { DocumentTypeName, fk_DocumentType, MimeType, pk_File, Candidate_UUID, Name, fk_Candidate } = document;

      const docType = documentTypes.find((type) => {
        return type.pk_DocumentType === fk_DocumentType;
      });

      return (
        <div
          key={document.Name}
          className="hover_blue icon_wrapper"
          onClick={() => {
            window.open(`/viewFile/${fk_Candidate}/${pk_File}/${fk_DocumentType}/${Name}`, '_blank').focus();
            // history.push(`/viewFile/${fk_Candidate}/${pk_File}/${fk_DocumentType}/${Name}`);
          }}
        >
          <a
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            target="_blank"
            // href={`/viewFile/${fk_Candidate}/${pk_File}/${fk_DocumentType}/${Name}`}
          >
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                className="font_awesome_icon"
                size="4x"
                style={{ color: 'rgb(51, 51, 51)' }}
                icon={determineIcon(MimeType)}
              />
              <h6 style={{ paddingLeft: '20px' }}>{DocumentTypeName ? DocumentTypeName : '----'}</h6>
            </td>
          </a>
        </div>
      );
    });
  };

  const renderCandidateData = () => {
    const { isFlexEvent } = selectedTimeSlot || {};

    if (selectedUser === 'fetching')
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',
            height: 300,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading options={{ labelText: 'Loading. . .' }} />
        </div>
      );
    // if (selectedTimeSlot && ((selectedTimeSlot.isFlexEvent && !selectedFlexEventCandidate) || !selectedUser)) {
    //   return <div style={style.simpleRow}>Select Candidate</div>;
    // }

    const candidateToUse = isFlexEvent ? selectedFlexEventCandidate : selectedUser || {};

    const hasPhoto = candidateToUse && candidateToUse.PhotoUrl;

    return (
      <div style={{ backgroundColor: 'white', flexDirection: 'column', display: 'flex', width: '100%' }}>
        {getEvalAndChangeTab ? (
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                  <div
                    style={{ width: '50%', position: 'relative', top: 0, cursor: hasPhoto ? 'pointer' : 'default' }}
                    onClick={() => {
                      if (hasPhoto) {
                        setShowLargePhoto(true);
                        setLargePhoto(candidateToUse.PhotoUrl);
                      }
                    }}
                  >
                    {hasPhoto && (
                      <img
                        style={{ height: 150, width: 150, padding: '10px', objectFit: 'contain' }}
                        src={candidateToUse.PhotoUrl}
                      />
                    )}
                    {!hasPhoto && (
                      <div
                        style={{
                          height: '150px',
                          width: '150px',
                          // border: '4px solid rgb(51, 51, 51)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} size="8x" />
                      </div>
                    )}
                    {(candidateEvaluationStatus && candidateEvaluationStatus.EnableDoNotRank) ||
                    candidateToUse.HasBeenRejectedForInterview ||
                    DNRStatus ? (
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Circle>
                          <p style={{ padding: 0, margin: 0 }}>{isEvaluate ? 'DNR' : 'DNI'}</p>
                        </Circle>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '20px',
                    paddingRight: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  <h4>
                    {candidateToUse.LastName}, {candidateToUse.FirstName}
                  </h4>
                  <h6>{candidateToUse.CandidateSelectedMedSchool}</h6>
                  <div style={{ width: '100%' }}>{determineStatusIcon(candidateToUse, getEvalAndChangeTab)}</div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '49%' }}>
                  {interviewIsToday &&
                  showMeetingButton &&
                  dContext &&
                  dContext.department &&
                  dContext.department.VirtualMeetingMode !== 'Disabled' ? (
                    <Button
                      className="longest-button-min-width"
                      disabled={disableJoinMeetingButton}
                      color="success"
                      style={{ minWidth: '95px' }}
                      onClick={(e) => {
                        e.preventDefault();

                        handleMeetingClick(selectedTimeSlot);
                        return;
                      }}
                    >
                      {disableJoinMeetingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
                    </Button>
                  ) : null}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  {determineStatusButton(candidateToUse, getEvalAndChangeTab)}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: isFlexEvent ? null : 280,
            height: isFlexEvent ? null : 'calc(100vh - 520px)',
            overflowY: isFlexEvent ? null : 'scroll',
            overflowX: 'hidden',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Row>
              <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <div
                          style={{ ...style.spacedBetweenRow, cursor: 'pointer' }}
                          onClick={() => {
                            setShowDocuments(!showDocuments);
                          }}
                        >
                          Documents {documents.length > 0 && <span>({documents.length} Available)</span>}{' '}
                          <FontAwesomeIcon icon={showDocuments ? faMinus : faPlus} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <Collapse isOpen={showDocuments}>
                    {!HideCandidateDocuments ? (
                      renderDocuments(documents)
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          color: 'red',
                          fontWeight: 'bold',
                          width: '100%',
                          textAlign: 'center',
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginTop: 20,
                        }}
                      >
                        Documents hidden by Administrator.
                      </div>
                    )}
                  </Collapse>
                </Table>
              </div>
            </Row>
            <Row>
              <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <div
                          style={{ ...style.spacedBetweenRow, cursor: 'pointer' }}
                          onClick={() => {
                            setShowCandidateData(!showCandidateData);
                          }}
                        >
                          Candidate Data <FontAwesomeIcon icon={showCandidateData ? faMinus : faPlus} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Collapse isOpen={showCandidateData} style={{ width: '100%' }}>
                  <div>
                    {!HideCandidateScores ? (
                      renderScoresAndAwards()
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          color: 'red',
                          fontWeight: 'bold',
                          width: '100%',
                          textAlign: 'center',
                          paddingLeft: 10,
                          paddingRight: 10,
                          marginTop: 20,
                        }}
                      >
                        Scores hidden by Administrator.
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            </Row>
            {}
          </div>
        </div>
      </div>
    );
  };
  const showMeetingButton = shouldJoinMeetingButtonBeVisisble(selectedTimeSlot, false, currentTime);

  // if ((!selectedUser || (selectedUser && selectedUser.pk_Candidate == null)) && selectedTimeSlot) {
  if (selectedTimeSlot && !isScheduleBlock) {
    const isBreak = !isFiller && !isCustomMeeting && !isPassingPeriod && !isScheduleBlock;
    const { CustomMeetingTitle, EventName, isCustomMeeting, isFlexEvent } = selectedTimeSlot || {};
    const slotEventName = CustomMeetingTitle || EventName;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: 80 }}>
            <div style={{ width: 80, height: 100, position: 'relative', top: 0 }}>
              <div
                style={{
                  width: 80,
                  height: 100,
                  // border: '4px solid rgb(51, 51, 51)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {isCustomMeeting ? (
                  <div style={{ fontSize: 50, fontWeight: 'bold' }}> {slotEventName[0].toUpperCase()} </div>
                ) : (selectedUser && selectedUser !== 'fetching') || isFlexEvent || isCustomMeeting || isBreak ? (
                  <FontAwesomeIcon icon={isFlexEvent ? faHandshake : faCoffee} style={{ fontSize: 50 }} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 18,
              // paddingTop: '20px',
              // paddingRight: '10px',
              // paddingBottom: '10px',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingLeft: 20,
              width: '100%',
            }}
          >
            {(selectedUser && selectedUser !== 'fetching') || isFlexEvent || isCustomMeeting || isBreak
              ? slotEventName || 'Break'
              : ''}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50%' }}>
            {interviewIsToday &&
            showMeetingButton &&
            dContext &&
            dContext.department &&
            dContext.department.VirtualMeetingMode !== 'Disabled' &&
            ((selectedUser && selectedUser !== 'fetching') ||
              isFlexEvent ||
              isCustomMeeting ||
              isBreak ||
              allDayRoom) ? (
              <Button
                className="longest-button-min-width"
                disabled={disableJoinMeetingButton}
                color={isCustomMeeting || isFlexEvent ? 'success' : 'warning'}
                style={{ minWidth: '95px' }}
                onClick={(e) => {
                  e.preventDefault();

                  handleMeetingClick(selectedTimeSlot);
                }}
              >
                {disableJoinMeetingButton || (selectedUser && selectedUser == 'fetching') ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : !isBreak ? (
                  'Join Meeting'
                ) : (
                  'Visit Break Room'
                )}
              </Button>
            ) : null}
          </div>
        </div>
        {isFlexEvent ? (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
            <Input
              type={'select'}
              value={selectedFlexEventCandidate ? selectedFlexEventCandidate.pk_Candidate : ''}
              onChange={(e) => {
                const newValue = JSON.parse(e.target.value);
                const matchingCandidate = newValue ? candidateInfo.find((c) => c.pk_Candidate === newValue) : {};
                setSelectedFlexEventCandidate(matchingCandidate);
              }}
            >
              <option value="" disabled>
                Select a Candidate
              </option>
              {flexEventCandidates.map((candidate) => {
                return (
                  <option key={candidate.pk_Candidate} value={candidate.pk_Candidate}>
                    {candidate.LastName}, {candidate.FirstName}
                  </option>
                );
              })}
            </Input>
          </div>
        ) : null}

        <div
          id="candidateDataContainer"
          style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto', height: 'calc(100vh - 500px)' }}
        >
          {(isFlexEvent && selectedFlexEventCandidate) || selectedUser ? (
            renderCandidateData()
          ) : isFlexEvent && !selectedFlexEventCandidate ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '30vh',
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              Select Candidate!
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  // if (!selectedUser || selectedUser.pk_Candidate == null) {
  //   return <div />;
  // }

  return renderCandidateData();
};

export default connect(mapStateToProps, null)(Portfolio);
