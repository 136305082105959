import React, { useEffect, useState } from 'react';
import style from './DirectApplication.style';
import { Button } from 'reactstrap';
import RezRateImg from '../../../../assets/images/header/rezRate.gif';
import { useHistory } from 'react-router';
import ApplicationLogin from './ApplicantLogin/ApplicantLogin';
import ApplicantLogin from './ApplicantLogin/ApplicantLogin';
import ApplicantProfile from './ApplicantProfile/ApplicantProfile';
import CreateApplicantAccount from './CreateApplicantAccount/CreateApplicantAccount';
import ApplicantHeader from './ApplicantHeader/ApplicantHeader';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { fetchData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import Swal from 'sweetalert2';
import { result } from 'lodash';

const DirectApplication = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Temporary state
  const [showMode, setShowMode] = useState('landing'); // Temporary state

  const [departments, setDepartments] = useState([]);

  const history = useHistory();
  const { pathname, search } = history.location;

  const { isAuthenticated, login, getToken, isLoading } = useKindeAuth();

  useEffect(() => {
    console.log('DirectApplication isAuthenticated: ', isAuthenticated);

    if (login && !isLoading) {
      if (isAuthenticated) {
        // history.push('/applicant/profile');
        getDepartments();
      } else {
        console.log('login: ', login);
        // handleLogin();
        login();
      }
    }
  }, [login, isLoading]);

  useEffect(() => {
    if (pathname.indexOf('/login') >= 0) {
      setShowMode('login');
    } else if (pathname.indexOf('/profile') >= 0) {
      setShowMode('profile');
    } else if (pathname.indexOf('/create_account') >= 0) {
      setShowMode('create_account');
    } else {
      setShowMode('profile');
    }
  }, [pathname]);

  const getDepartments = async () => {
    console.log('getDepartments firing ');

    return;
    const token = await getToken(); // might be causing auth0 errrors when firing before valid.

    fetchData('user/allDepartments', null, formatBearerToken(token))
      .then((result) => {
        console.log('result: ', result);
        setDepartments(result.data);
      })
      .catch((err) => {
        console.log('getDepartments err: ', err);
        Swal.fire({
          title: 'Error!',
          text: 'Failed to retrieve departments',
          type: 'error',
        });
      });
  };

  const renderLogin = () => {
    return <ApplicantLogin setShowMode={setShowMode} />;
  };

  const renderProfile = () => {
    return <ApplicantProfile setShowMode={setShowMode} />;
  };

  const renderCreateApplicantAccount = () => {
    return <CreateApplicantAccount setShowMode={setShowMode} />;
  };

  const renderLanding = () => {
    return (
      <div style={{ ...style.simpleRow, width: '100%', height: '100%', alignItems: 'center' }}>
        <div style={{ ...style.simpleColumn, height: '100%' }}>
          <div style={{ ...style.simpleRow, marginBottom: 10 }}>
            <img src={RezRateImg} alt="RezRate Logo" style={{ width: '30vh', height: '30vh' }} />
          </div>
          {renderLogin()}
          {/* <div style={{ ...style.simpleRow, marginBottom: 10 }}>
            <Button
              size="lg"
              color="primary"
              onClick={() => {
                history.push('/applicant/applicant_login');
              }}
            >
              <b>Login</b>
            </Button>
          </div>
          <div style={style.simpleRow}>
            <Button
              size="lg"
              color="success"
              onClick={() => {
                history.push('/applicant/create_applicant_account');
              }}
            >
              <b>Create Application Account</b>
            </Button>
          </div> */}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (showMode) {
      case 'login':
        return renderLogin();
      case 'profile':
        return renderProfile();
      case 'create_account':
        return renderCreateApplicantAccount();
      default:
        return renderProfile();
    }
  };

  return (
    <div
      style={{
        ...style.simpleRow,
        width: '100%',
        height: 'calc(100vh - 80px)',
        alignItems: showMode !== 'landing' ? 'flex-start' : 'center',
      }}
    >
      <div style={{ ...style.simpleColumn }}>
        {showMode !== 'landing' && <ApplicantHeader showMode={showMode} />}
        {renderContent()}
      </div>
    </div>
  );
};

export default DirectApplication;
